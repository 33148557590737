import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';
import { icLinks } from '../lib/Constants';

function LogoutRedirect() {
  const { logout } = useAuth0();

  logout({ returnTo: icLinks.loginURL });

  return (<Loading />);
}

export default LogoutRedirect;
