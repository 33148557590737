import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import App from './App';
// import Home from './views/Home';
import LoginError from './views/LoginError';
import Profile from './views/Profile';
import NotFound from './views/NotFound';
import {
  CommunityRedirect,
  LoginRedirect,
  LogoutRedirect,
  SignUpRedirect,
} from './components';

import { routes } from './lib/Constants';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path={routes.home} element={<App />}>
            <Route index element={<LoginRedirect />} />
            <Route path={routes.profile} element={<Profile />} />
            <Route path={routes.loginError} element={<LoginError />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path={routes.logIn} element={<LoginRedirect />} />
          <Route path={routes.logOut} element={<LogoutRedirect />} />
          <Route path={routes.signUp} element={<SignUpRedirect />} />
          <Route path={routes.communityRedirect} element={<CommunityRedirect />} />

        </Routes>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
