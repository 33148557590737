import React, { useState } from 'react';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
} from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import LoginLink from './LoginLink';

import {
  appConstants, icAssets, icLinks, routes,
} from '../lib/Constants';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () => logout({
    returnTo: window.location.origin,
  });

  return (
    <Navbar bg="white" variant="light" expand="md">
      <Container>
        <Link to="/" className="text-decoration-none">
          <Navbar.Brand>
            <img
              src={icAssets.appBrandLogo}
              alt={appConstants.defaultAppTitle}
              style={{ maxHeight: '30px' }}
            />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle onClick={toggle} />

        <Navbar.Collapse>
          <Nav className="ms-auto">
            {!isAuthenticated && (
              <NavItem>
                <LoginLink />
              </NavItem>
            )}
          </Nav>
          {isAuthenticated && (
            <Nav className="me-auto">
              <NavItem>
                <RouterNavLink to={routes.home} className="nav-link">
                  Home
                </RouterNavLink>
              </NavItem>
              <NavItem>
                <NavLink href={icLinks.communityHome} target="_blank">
                  {appConstants.communityAppTitle}
                </NavLink>
              </NavItem>
            </Nav>
          )}

          {isAuthenticated && user && (
            <>
              <Nav className="d-none d-md-block">
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle id="profileDropDown" as={NavLink}>
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="36"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end">
                    <Dropdown.Item>{user.name}</Dropdown.Item>
                    <Dropdown.Item as={RouterNavLink} to={routes.profile}>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => logoutWithRedirect()}>
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>

              <Nav className="d-md-none justify-content-between">
                <NavItem>
                  <RouterNavLink to={routes.profile} className="nav-link">
                    Profile
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <RouterNavLink
                    to="#"
                    onClick={() => logoutWithRedirect()}
                    className="nav-link"
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
