import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './atoms/Loading';
import { NavBar, PublicNavBar } from './components';

import './scss/initialized.scss';

function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (error) {
    return (
      <div>
        Oops...
        {error.message}
      </div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (

    <div id="app" className="d-flex flex-column h-100">
      {isAuthenticated
        ? (<NavBar />)
        : (<PublicNavBar />)}
      <Outlet />
    </div>
  );
}

export default App;
