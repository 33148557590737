import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Loading from './Loading';
import { icLinks } from '../lib/Constants';

function CommunityRedirect() {
  const [searchParams] = useSearchParams();

  if (searchParams.get('error') == null && searchParams.get('error_description') == null) {
    window.location.replace(icLinks.communityHome);
  } else {
    window.location.replace(icLinks.loginErrorURL);
  }

  return (<Loading />);
}

export default CommunityRedirect;
