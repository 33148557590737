import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loading() {
  return (
    <Spinner
      as="span"
      animation="border"
      variant="dark"
    />
  );
}

export default Loading;
