import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';
import { icLinks } from '../lib/Constants';

function SignUpRedirect() {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect({
    screen_hint: 'signUp',
    redirectUri: icLinks.communityHomeRedirectUri,
  });

  return (<Loading />);
}

export default SignUpRedirect;
