import React from 'react';
import { NavLink } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

function LoginLink() {
  const { loginWithRedirect } = useAuth0();

  return (
    <NavLink
      color="primary"
      onClick={() => loginWithRedirect()}
    >
      Log In
    </NavLink>
  );
}

export default LoginLink;
