import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';
import { icLinks } from '../lib/Constants';

function LoginRedirect() {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect({
    redirectUri: icLinks.communityHomeRedirectUri,
  });

  return (<Loading />);
}

export default LoginRedirect;
