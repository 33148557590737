import React from 'react';
import { Navbar } from 'react-bootstrap';

function PublicNavBar() {
  return (
    <Navbar bg="transparent" variant="dark" className="p-0" />
  );
}

export default PublicNavBar;
