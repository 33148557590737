import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { icLinks } from '../lib/Constants';
import DenLogo from '../atoms/DenLogo';

import './login-error.css';

function LoginError() {
  const [isRedirecting, setRedirecting] = useState(false);

  const { loginWithRedirect } = useAuth0();

  const noop = () => {};

  const handleClick = () => {
    setRedirecting(true);
    loginWithRedirect({
      redirectUri: icLinks.communityHomeRedirectUri,
    });
  };

  return (
    <div className="new-login-error-container">
      <div className="new-login-error-content">
        <DenLogo className="den-logo" />
        <div className="new-login-error--text-content">
          <p className="new-login-error--text-content--first-text">
            We&apos;re sorry but that account is not authorized for the Honeybadger Den.
          </p>
          <p className="new-login-error--text-content--second-text">
            Please try another account or contact
            {' '}
            <a className="mailto" target="_blank" href="mailto:community@intialized.com" rel="noreferrer">community@initialized.com</a>
            {' '}
            to get your account authorized.
          </p>
        </div>
        <Button
          className="new-login-error--button"
          disabled={isRedirecting}
          onClick={!isRedirecting ? handleClick : noop}
        >
          {isRedirecting ? 'Redirecting...' : 'Return to login'}
        </Button>
      </div>
    </div>
  );
}

export default LoginError;
