import React from 'react';

function NotFound() {
  return (
    <>
      <h1>Not Found!</h1>
      <hr />
    </>
  );
}

export default NotFound;
