export const appConstants = {
  defaultAppTitle: 'The Honeybadger Den',
  communityAppTitle: 'Community',
};

export const routes = {
  communityRedirect: '/community',
  home: '/',
  loginError: '/loginError',
  logIn: '/login',
  logOut: '/logout',
  profile: '/profile',
  signUp: '/signup',
};

export const icLinks = {
  hbdHome: 'https://hbd.initialized.com',
  communityHome: 'https://community.initialized.com',
  communityHomeRedirectUri: 'https://hbd.initialized.com/community',
  loginErrorURL: 'https://hbd.initialized.com/loginerror',
  loginURL: 'https://hbd.initialized.com/login',
  privacyURL: 'https://initialized.com/privacy',
  termsOfServiceURL: 'https://initialized.com/terms-of-service',
};

export const icAssets = {
  appLogo: 'https://assets.initialized.com/HBD%20Header%20Sign%20In.png',
  appBrandLogo: 'https://assets.initialized.com/logo.svg',
};
