import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../atoms/Loading';

export function ProfileComponent() {
  const { user } = useAuth0<IAuth0User>();

  return (
    <Container>
      <h1>Welcome!</h1>
      {user
        ? (
          <Container className="mb-5">
            <Row className="align-items-center profile-header mb-5 text-center text-md-left">
              <Col md={2}>
                <img
                  src={user.picture}
                  alt="Profile"
                  className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                />
              </Col>
              <Col md>
                <h2>{user.name}</h2>
                <p className="lead text-muted">{user.email}</p>
              </Col>
            </Row>
            <Row>
              {JSON.stringify(user, null, 2)}
            </Row>
          </Container>
        ) : (
          <h1>Access Denied</h1>
        ) }
    </Container>
  );
}

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
