import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './config';

export interface IAuth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

function Auth0ProviderWithHistory({ children }: IAuth0ProviderWithHistoryProps): JSX.Element {
  const config = getConfig();
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    onRedirectCallback,
  };

  return (
    <Auth0Provider
      {...providerConfig}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
